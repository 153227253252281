<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo" />
  </div>
  <div class="header">
    <h1>Formular za procenu radnog učinka - Samoprocena</h1>
    <h3>(1=Ne Zadovoljava Očekivanja)</h3>
    <h3>(2=Potrebno Poboljšanje)</h3>
    <h3>(3=Zadovoljava Očekivanja)</h3>
    <h3>(4=Iznad Očekivanja)</h3>
  </div>

  <div class="kompetence" v-if="kompetenceProc != 0">
    <h3>Kompetence</h3>

    <table id="kompetenceTable">
      <tr>
        <th>Opis:</th>
        <th>Samoprocena</th>
      </tr>
      <tr v-for="(komp, i) in kompetence" v-bind:key="i">
        <td>
          {{ komp.name }}
          <button
            class="definicija"
            type="button"
            name="button"
            @Click="swapBoolKompetence(i)"
          >
            Definicija
          </button>

          <p v-if="kompetenceBool[i].bool" style="text-align: left;">
            <span style="white-space: pre-line">
              {{ kompetenceBool[i].comment }}
            </span>
          </p>
        </td>
        <td>{{ komp.samoProcena }}</td>
      </tr>
    </table>
  </div>

  <div class="licni" v-if="licniProc != 0">
    <h3>{{ licniKpiTitle }}</h3>

    <table id="LicniTable">
      <tr>
        <th>Opis:</th>
        <th>Samoprocena</th>
      </tr>
      <tr v-for="(komp, i) in licniKpi" v-bind:key="i">
        <td>
          {{ komp.name }}
          <button
            class="definicija"
            type="button"
            name="button"
            @Click="swapBoolLicni(i)"
          >
            Definicija
          </button>

          <p v-if="licniBool[i].bool" style="text-align: left;">
            <span style="white-space: pre-line">
              {{ licniBool[i].comment }}
            </span>
          </p>
        </td>
        <td>{{ komp.samoProcena }}</td>
      </tr>
    </table>
  </div>

  <div class="licni" v-if="kompanijskiProc != 0">
    <h3>Kompanijski KPI</h3>

    <table id="LicniTable">
      <tr>
        <th>Opis:</th>
        <th>Samoprocena</th>
      </tr>
      <tr v-for="(komp, i) in kompanijskiKpi" v-bind:key="i">
        <td>
          {{ komp.name }}
          <button
            class="definicija"
            type="button"
            name="button"
            @Click="swapBoolKompanijski(i)"
          >
            Definicija
          </button>

          <p v-if="kompanijskiBool[i].bool" style="text-align: left;">
            <span style="white-space: pre-line">
              {{ kompanijskiBool[i].comment }}
            </span>
          </p>
        </td>
        <td>{{ komp.samoProcena }}</td>
      </tr>
    </table>
  </div>

  <div class="komentar">
    <h4>Komentar</h4>

    <p style="text-align: left;">
      <span style="white-space: pre-line">
        {{ komentarSamoProcena }}
      </span>
    </p>
    <!-- <input
      class="form-control"
      type="text"
      placeholder=""
      name="komentar"
      v-model="komentarSamoProcena"
      id="komentar"
      > -->
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";

export default {
  computed: mapGetters(["user", "companys", "usersSameCompany"]),
  data() {
    return {
      companyKpiConnector: 0,
      odgovori: [1, 2, 3, 4],
      komentar: "",
      kompetence: [],
      kompanijskiKpi: [],
      licniKpi: [],
      collegue: "",
      kompanijskiProc: 0,
      licniProc: 0,
      kompetenceProc: 0,
      kompetenceBool: [],
      kompanijskiBool: [],
      licniBool: [],
      lastEval: 0,
      komentarSamoProcena: "",
      licniKpiTitle: "Lični KPI",
    };
  },
  methods: {
    ...mapActions([
      "getProfile",
      "getCompanies",
      "getCollegues",
      "setLicniKpiSamoProcena",
    ]),
    swapBoolKompetence(i) {
      if (this.kompetenceBool[i].bool) {
        this.kompetenceBool[i].bool = false;
      } else {
        this.kompetenceBool[i].bool = true;
      }
    },
    swapBoolKompanijski(i) {
      if (this.kompanijskiBool[i].bool) {
        this.kompanijskiBool[i].bool = false;
      } else {
        this.kompanijskiBool[i].bool = true;
      }
    },
    swapBoolLicni(i) {
      if (this.licniBool[i].bool) {
        this.licniBool[i].bool = false;
      } else {
        this.licniBool[i].bool = true;
      }
    },
  },
  async created() {
    await this.getProfile();
    await this.getCompanies();
    await this.getCollegues(this.user.type);
    let tempCompany;
    for (var i = 0; i < this.companys.length; i++) {
      if (this.user.type == this.companys[i].name) {
        tempCompany = this.companys[i];
      }
    }

    this.lastEval = this.user.performanceEvaluation.length - 1;
    this.companyKpiConnector = this.user.performanceEvaluation[
      this.lastEval
    ].companyKpiConnector;

    let brojac =
      tempCompany.performanceEvaluation[this.companyKpiConnector].kompanijski
        .length;
    for (i = 0; i < brojac; i++) {
      let komp = {
        bool: false,
        comment:
          tempCompany.performanceEvaluation[this.companyKpiConnector]
            .kompanijski[i].comment,
      };
      this.kompanijskiBool.push(komp);
    }

    brojac =
      tempCompany.performanceEvaluation[this.companyKpiConnector].kompetence
        .length;
    for (i = 0; i < brojac; i++) {
      let komp = {
        bool: false,
        comment:
          tempCompany.performanceEvaluation[this.companyKpiConnector]
            .kompetence[i].comment,
      };
      this.kompetenceBool.push(komp);
    }

    this.komentarSamoProcena = this.user.performanceEvaluation[
      this.lastEval
    ].komentarSamoProcena;

    brojac = this.user.performanceEvaluation[this.lastEval].licniKpi.length;
    for (i = 0; i < brojac; i++) {
      let komp = {
        bool: false,
        comment: this.user.performanceEvaluation[this.lastEval].licniKpi[i]
          .comment,
      };
      this.licniBool.push(komp);
    }

    this.kompetence =
      tempCompany.performanceEvaluation[this.companyKpiConnector].kompetence;
    for (i = 0; i < this.kompetence.length; i++) {
      this.kompetence[i].samoProcena = this.user.performanceEvaluation[
        this.lastEval
      ].kompetence[i].samoProcena;
    }
    this.kompanijskiKpi =
      tempCompany.performanceEvaluation[this.companyKpiConnector].kompanijski;
    for (i = 0; i < this.kompanijskiKpi.length; i++) {
      this.kompanijskiKpi[i].samoProcena = this.user.performanceEvaluation[
        this.lastEval
      ].kompanijski[i].samoProcena;
    }
    if (
      this.user.performanceEvaluation[this.lastEval].licniKpiTitle != undefined
    ) {
      this.licniKpiTitle = this.user.performanceEvaluation[
        this.lastEval
      ].licniKpiTitle;
    } else {
      this.licniKpiTitle = "Lični KPI";
    }
    this.licniKpi = this.user.performanceEvaluation[this.lastEval].licniKpi;
    this.kompetenceProc =
      tempCompany.performanceEvaluation[
        this.companyKpiConnector
      ].kompetenceProcenat;
    this.licniProc =
      tempCompany.performanceEvaluation[this.companyKpiConnector].licniProcenat;
    this.kompanijskiProc =
      tempCompany.performanceEvaluation[
        this.companyKpiConnector
      ].kompanijskiProcenat;
  },
  beforeCreate: function() {
    document.body.className = "other";
  },
};
</script>

<style lang="css" scoped>
table,
th,
td {
  border: 1px solid black;
  text-align: center;
}
tr:nth-child(even) {
  background-color: #bbbbbb;
}
tr:nth-child(odd) {
  background-color: #ffffff;
}
tr:hover {
  background-color: #afa;
}
table {
  margin: auto;
}
h4 {
  text-align: center;
}
h1 {
  text-align: center;
}
.header {
  background-color: #ffaaaa;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}
.podaciOZaposlenom {
  background-color: #ffffaa;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-top: 0px;
  border-radius: 15px;
  padding: 10px;
}
.formular {
  background-color: #dddd88;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-top: 0px;
  border-radius: 15px;
  padding: 10px;
}

.komentar {
  background-color: #ffffaa;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-top: 0px;
  border-radius: 15px;
  padding: 10px;
}
.submitDiv {
  width: 100%;
}
.btn {
  width: 100%;
  background-color: #003366;
}
.definicija {
  background-color: #ffff88;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
  float: right;
}
.definicija:hover {
  background-color: #dddd66;
}
</style>
